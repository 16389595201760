import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TwoColumn from "../../components/two-column"
import CustomerJourney from "../../components/customer-journey"
// import Gateways from "../../components/gateways"
import PaymentGateway from "../../components/payment-gateway"

// import { Helmet } from 'react-helmet'

const bannerContent = {
  title:
    "Don't get tied down to one payment gateway. Discover the Billsby Vault.",
  description: [
    {
      text:
        "As you grow your business, you'll want the flexibility to switch payment gateways without costly migrations. Otherwise you might find yourself trapped with one gateway partner and unable to negotiate effectively.",
    },
  ],
  withPromotion: false,
  textColor: "#252422",
  backgroundColor: "#F3F5FB",
  hasBg: false,
  buttons: [
    {
      isExternal: true,
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      buttonText: "Sign up today",
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
  isBanner: true,
  image: require("../../images/billsby-payment-gateways.svg"),
  imageName: "billsby banner image",
  svg: true,
  sectionPaymentGateways: true,
}

const billsbyVault = {
  title: "Cards are securely tokenized in the Billsby Vault.",
  list: [
    {
      title: "We'll take care of your cards",
      description: (
        <>
          When customers provide a payment token, we'll store it securely in the
          Billsby Vault - a PCI-DSS compliant solution that sits between you and
          your gateway.
        </>
      ),
    },
    {
      title: "You won't touch your cardholder data",
      description: (
        <>
          Across both of our checkout solutions, you don't need to touch,
          process or store cardholder data - so you'll qualify for the simplest
          level of PCI DSS paperwork.
        </>
      ),
    },
    {
      title: "Never worry about gateway migrations.",
      description: (
        <>
          Because your cards are stored in the Billsby Vault, you'll never need
          to migrate tokens from one payment gateway to another.
        </>
      ),
    },
  ],
  image: require("../../images/billsby-payment.svg"),
  svg: true,
  imageName: "billsby-payment",
}

const switchPaymentGateway = {
  title: "Switch payment gateways at any time with one click.",
  list: [
    {
      title: "Change gateways within your control panel",
      description: (
        <>
          If you get a better processing deal, you can swap all processing from
          one gateway to another in one click from your control panel.
        </>
      ),
    },
    {
      title: "Choose a different gateway for each currency",
      description: (
        <>
          Want to transact using Stripe for USD, Adyen for GBP and Checkout.com
          for EUR? No problem. You can pick a separate gateway for each
          currency.
        </>
      ),
    },
    {
      title: "Your customers won't know there's been a change.",
      description: (
        <>
          Because cards are stored in the Billsby Vault, there's no need for
          your customers to provide card details again or revalidate -
          everything just works seamlessly.
        </>
      ),
    },
  ],
  align: "right",
  image: require("../../images/billsby-checkout.svg"),
  imageName: "billsby-checkout",
  svg: true,
}

const paymentIndustryCompliance = {
  title: "Payment industry compliance is done for you, too.",
  list: [
    {
      title: "Reduce your PCI-DSS compliance burden",
      description: (
        <>
          With Billsby, cards are securely tokenised in the Billsby Vault -
          whichever integration approach you choose you'll never be in
          possession of a customers payment information. This keeps your PCI-DSS
          compliance burden as low as possible, with quick and simple
          implementation.
        </>
      ),
    },
    {
      title: "Get ready for SCA",
      description: (
        <>
          SCA, or Secure Card Authentication, is the next big piece of
          legislation that'll affect your card payments and subscription
          customers. If you're using Billsby Checkout, SCA compliance will be
          automatically enabled on your website as it becomes necessary with no
          development work required.
        </>
      ),
    },
  ],
  image: require("../../images/billsby-compliance.svg"),
  imageName: "billsby-compliance",
  svg: true,
}

const takeYourTokens = {
  title: "If you ever want to leave, you can take your tokens with you.",
  description: [
    {
      text:
        "We won't hold your tokens to ransom. If you ever want to leave Billsby we'll work with your new PCI-DSS compliant provider to arrange a token migration, and you can access all of your other data from our API.",
    },
    {
      text:
        "For our part, we'll only charge for this service based on what it actually costs us to deliver it. It's just about doing the right thing.",
    },
  ],
  imagePosition: "full-right",
  textColor: "#fff",
  backgroundColor: "#243f5c",
  backgroundImage: require("../../images/doggie@2x.png"),
  backgroundPosition: "right",
  sectionProtection: true,
}

const paymentProcessingContent = {
  layout: 2,
  title: "Bring a gateway, then change gateways anytime",
  titleUS: "Bring a gateway, or let us introduce you for preferred rates",
  textUS:
    "We’ve partnered with some of the best payment gateways around to give you excellent rates, priority underwriting and exceptional customer service - with easy integration into your Billsby account",
  offers: [
    {
      id: "low-risk",
      title: "Low-risk merchants",
      titleLayout2: "low risk merchants",
      withPopUp: false,
      popUpContent: <></>,
      boxContentLeft: {
        title: "Payment processing",
        subtitle: (
          <>
            <img
              className="paymentcloud-logo"
              src={require("../../images/paymentcloud-white@2x.png")}
              alt="Payment Cloud"
            />
            <img
              className="cardconnect-logo"
              src={require("../../images/cardconnect-white.svg")}
              alt="Card Connect"
            />
          </>
        ),
      },
      boxContentRight: {
        layout: 1,
        items: [
          {
            valueBig: "2.",
            valueStrong: "65%",
            valueNormal: " + $0.15",
            label: "For Visa, MasterCard and Discover",
            withPlus: false,
          },
          {
            valueBig: "2.",
            valueStrong: "9%",
            valueNormal: " + $0.10",
            label: "For American Express",
            withPlus: false,
          },
        ],
        description: (
          <>
            For qualified businesses. No monthly fees. No setup fees. $20
            minimum monthly charge.{" "}
            <span>
              Further discounts and Interchange+ pricing available for volumes
              over $25,000 per month.
            </span>{" "}
            Terms apply. See the link in your Billsby account for details.
          </>
        ),
      },
    },
    {
      id: "high-risk",
      title: "High-risk merchants",
      titleLayout2: "high risk merchants",
      withPopUp: true,
      popUpContent: {
        text: (
          <>
            <p>
              Merchants in industries like investment and credit services, money
              and legal services, gambling, regulated products and services
              (tobacco, cannabis, CBD and the like), adult content and services,
              network marketing, nutraceuticals, social media activity and video
              game or virtual world credits are often classified as ‘high risk’,
              because it’s more likely they’ll encounter chargebacks. It’s not a
              moral or judgement call, it’s just the statistics.
            </p>
            <p>
              These merchants tend to need specialist underwriting, and will pay
              a higher fee than merchants in other categories at first, with the
              chance of a rate review down the line.
            </p>
          </>
        ),
      },
      boxContentLeft: {
        title: "Payment processing",
        subtitle: (
          <>
            <span>Powered by </span>
            <img
              className="paymentcloud-logo"
              src={require("../../images/paymentcloud-white@2x.png")}
              alt="Payment Cloud"
            />
          </>
        ),
      },
      boxContentRight: {
        layout: 1,
        items: [
          {
            valueBig: "3.",
            valueStrong: "95%",
            valueNormal: " + $0.25",
            label: "Typical merchant account rate",
            withPlus: true,
          },
          {
            valueBig: "$19",
            valueStrong: "",
            valueNormal: " + $0.10",
            label: "Typical payment gateway rate",
            withPlus: false,
          },
        ],
        description: (
          <>
            <span>Plus $49 monthly fee for merchant account.</span> Typical rate
            for eligible high-risk merchants. Underwriting required.
            Well-established businesses with low chargeback rates may qualify
            for preferred pricing. $19 per month plus $0.10 per transaction
            applies to typical pricing for Authorize.net account acquired
            through PaymentCloud. Full terms and application form in your
            Billsby account.
          </>
        ),
      },
    },
    {
      id: "high-volume",
      title: "Merchants with trading history",
      titleLayout2: "high volume merchants",
      withPopUp: false,
      popUpContent: <></>,
      boxContentLeft: {
        title: "Payment processing",
        subtitle: (
          <>
            <span>For</span>
            <span className="bold">merchants processing higher volumes</span>
          </>
        ),
      },
      boxContentRight: {
        layout: 2,
        gatewayList: [
          {
            img: require("../../images/cardconnect-gray.svg"),
            cardConnect: true,
            imgAlt: "Card Connect",
            desc: "For clients processing $25,000+ per month",
          },
          {
            img: require("../../images/paymentcloud@2x.png"),
            paymentCloud: true,
            imgAlt: "Payment Cloud",
            desc: "For clients of all sizes with established trading history",
          },
        ],
        description: (
          <>
            Preferred Interchange+ pricing is available to save you money on
            your transactions based on your industry sector and past chargeback
            rate. In your account, ask for an introduction to our recommended
            gateways.
          </>
        ),
      },
    },
  ],
  merchantsTitle:
    "Or bring your own payment gateway and merchant account - you can switch anytime",
  merchantsTitleNonUS:
    "Bring your own payment gateway and merchant account - you can switch anytime",
  merchantsLogo: [
    {
      img: require("../../images/adyen-gray.svg"),
      gatewayName: "Adyen",
    },
    {
      img: require("../../images/authorizenet-gray.svg"),
      gatewayName: "Authorize.net",
    },
    {
      img: require("../../images/braintree-gray.svg"),
      gatewayName: "Braintree.com",
    },
    {
      img: require("../../images/Clover-Connect-Grey-Logo@2x.png"),
      gatewayName: "Clover Connect",
    },
    {
      img: require("../../images/checkout-gray.svg"),
      gatewayName: "Checkout",
    },
    {
      img: require("../../images/ixopay-gray.svg"),
      gatewayName: "IXOPAY",
    },
    {
      img: require("../../images/nmi-gray@2x.png"),
      gatewayName: "NMI",
    },
    {
      img: require("../../images/paymentcloud-gray@2x.png"),
      gatewayName: "Payment Cloud",
    },
    {
      img: require("../../images/stripe-gray.svg"),
      gatewayName: "Stripe",
    },
  ],
}

const PaymentGateways = () => {
  return (
    <Layout className="payment-gateway">
      <SEO
        title="Payment Gateways | Billsby | Powerful, customizable subscription billing software"
        description="Need subscription billing software integrated with Adyen, Authorize.net, Braintree, Checkout.com and Stripe with full PCI-DSS compliance? We've got you covered."
        url="https://www.billsby.com/product/payment-gateways"
      />

      <TwoColumn content={bannerContent} />
      <CustomerJourney content={billsbyVault} />
      <CustomerJourney content={switchPaymentGateway} />

      <PaymentGateway content={paymentProcessingContent} />

      <CustomerJourney content={paymentIndustryCompliance} />
      <TwoColumn content={takeYourTokens} />
    </Layout>
  )
}

export default PaymentGateways
